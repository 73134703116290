// this state is used when trying to access a single tournament
/* eslint-disable*/
import axios from "axios";
const state = {
    tournamentItem: null,
    playerIsParticipant: false,
    playerIsCheckedIn: false,
    playerTeamIsParticipant: false,
    playerTeamIsCheckedIn: false,
    playerTeams: [],
    teamParticipatedId: null,
    fetchingTeams: false,
    isLoadingAction: false,
    participantType: null,
    twitchIsLive: false,
    participationCheckComplete: false,
    apiUrl: "https://lgame.gg/api/v1/",
   // apiUrl: "http://lgame.gg:8001/api/v1/",
};
const getters = {};
const actions = {
    getCurrentTournament({commit, dispatch, state}, slug){
        return axios.get(state.apiUrl+'tournaments/slug/'+slug)
            .then(response => {
                if(response.data.success){
                    commit('setCurrentTournament', response.data.data);
                    dispatch('checkParticipation');
                    console.log('tournament id: '+state.tournamentItem.id) // ctrl+v Yassine
                    console.log(response);
                    return {
                        success: true,
                    }
                }else{
                    if(response.data.notFound){
                        return {
                            success: false,
                            notFound: true,
                        }
                    }
                    console.log('tournament not found ')
                    console.log(response);
                    return {
                        success: true,
                    }
                }
            }).catch(error => {
                console.log("Error:: ", error);
                return {
                    success: false,
                }
            });
    },
    getTournament({commit,state, dispatch}, tournamentId){
        console.log("in get tournament ....")
        axios.get(state.apiUrl+'tournaments/'+tournamentId)
        .then(response => {
            commit('setCurrentTournament', response.data.data);
            dispatch('checkParticipation');
        }).catch(error => {
            console.log('error');
            console.log(error);
        });

    },
    /*
    * This function checks if a player/team is participated in a tournament
    * */
    checkParticipation({commit, state, rootState}){
        console.log('checkParticipation called');
        if(!rootState.currentUser.isAuthenticated){
            console.log('checkParticipation called === USER NOT AUTH ');
            state.participationCheckComplete = true;
            return; // user not logged in
        }
       const playerId = rootState.currentUser.user.player.id;
       console.log(`playerId:${playerId}, tournamentId: ${state.tournamentItem.id}`);
       return axios.post(state.apiUrl+'user/player/participant-in', {
           tournament_id: state.tournamentItem.id,
           player_id: playerId,
           participant_type: state.tournamentItem.settings.play_mode.size > 1 ? 'team' : 'player',
           game_id: state.tournamentItem.game_id
       })
           .then(response => {
               console.log('check participation log ======================= ')
               console.log(response);
               console.log('check participation log ==================')
               if(response.data.success === true){
                   console.log('is participant: ');
                   console.log(response.data.isParticipant);
                   if(state.tournamentItem.settings.play_mode.size === 1){
                       commit('checkParticipation', response.data.isParticipant);
                       commit('checkPlayerIn', {
                           checkinValue: response.data.isCheckedIn
                       });
                   }else{
                       commit('checkTeamParticipation', response.data);
                       commit('checkTeamIn', {
                           checkinValue: response.data.isCheckedIn
                       });
                   }

               }
           })
           .catch(error => {
               console.log(error.response);
           })
    },
    checkPlayerIn({commit, state, rootState}, payload){
        const tournamentId = state.tournamentItem.id;
        const playerId = rootState.currentUser.user.player.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/checkin',{
            checkinValue: payload.checkinValue,
            participant_type: 'Player',
            player_id: playerId,
        })
            .then(response => {
                if(response.data.success){
                    commit('checkPlayerIn', {
                        participants: response.data.data,
                        checkinValue: payload.checkinValue,
                    });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    },
    /**
     *  this function is used by player to participate in tournament
     */
    setParticipant({commit, state, rootState}, payload){
        const playerId = rootState.currentUser.user.player.id;
        const tournamentId = state.tournamentItem.id;
        console.log('test =============================')
        console.log(payload?.customFieldsValue);
        console.log('test =============================')
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/participate', {
            participant_id: playerId,
            participant_type: 'Player',
            custom_fields: payload?.customFieldsValue,
        })
            .then(response => {
                if(response.data.success){
                    console.log('i am here in participate inside success ')
                    let currentTournament = rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId);
                    if(currentTournament){
                        currentTournament.participants_players = response.data.data;
                        currentTournament.participants_players_count += 1;
                    }
                    console.log('passed find ');
                    commit('setPlayerIsParticipant', response.data.data);
                }
            })
            .catch(error => {
                console.log('===================================== catch error message START =========================')
                console.log(error)
                console.log('===================================== catch error message END =========================')
            });
    },

    /**
     * this function is used by a player to cancel his participation
     */
    cancelParticipation({commit, state, rootState}){
        const tournamentId = state.tournamentItem.id;
        const playerId = rootState.currentUser.user.player.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/remove', {
            participant_id: playerId,
            participant_type: 'Player',
        })
            .then(response => {
                if(response.data.success){
                    const currentTournament = rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId);
                    console.log(currentTournament);
                    /*if(currentTournament){

                    }

                    rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId).participants_players = response.data.data;
                    rootState.tournament.tournamentsList.find(tournament => tournament.id === tournamentId)
                        .participants_players_count -= 1;*/
                    commit('cancelParticipation', response.data.data);
                }
            })
    },

    /**
     *
     */
    getCurrentPlayerTeams({commit, state, rootState}){
        state.fetchingTeams = true;
        const gameId = state.tournamentItem.game_id;
        const playerId = rootState.currentUser.user.player.id;
        const playModeSize = state.tournamentItem.settings.play_mode.size
        return axios.post(state.apiUrl+'user/player/'+playerId+'/teams/by-game?playMode='+playModeSize, {
            game_id: gameId,
        })
            .then(response => {
                console.log(response.data);
                commit('setCurrentPlayerTeams', response.data.data);
            }).catch(error => {
                console.log(error.response);
            })
    },


    /**
     *  this function is used by player to participate by his team in tournament
     */
    setParticipantTeam({commit, state, rootState}, payload){
        const teamId = payload.teamId;
        const tournamentId = state.tournamentItem.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/participate', {
            participant_id: teamId,
            participant_type: 'Team',
            custom_fields: payload.customFieldsValue,
        })
            .then(response => {
                if(response.data.success){
                    console.log('========================================== team participation log ================================')
                    console.log(response.data)
                    console.log('========================================== team participation log ================================')
                    let currentTournament =  state.tournamentItem;
                    if(currentTournament){
                        currentTournament.participants_teams = response.data.data;
                        currentTournament.participants_teams_count += 1;
                    }
                    commit('setTeamIsParticipant', {
                        participants: response.data.data,
                        participantTeamId: teamId
                    });
                   
                }
            })
            .catch(error => {
                console.log("error set participantTeam")
                console.log(error)
            })
    },
    cancelTeamParticipation({commit, state, rootState}){
        const teamId = state.teamParticipatedId;
        const tournamentId = state.tournamentItem.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/remove', {
            participant_id: teamId,
            participant_type: 'Team',
        }).then(response => {
            console.log('========================================== team participation log ================================')
            console.log(response.data)
            console.log('========================================== team participation log ================================')
            
            state.tournamentItem.participants_teams = response.data.data;
            
            commit('cancelTeamParticipation', response.data.data);
        }).catch(error => {
            console.log(error);
        })
    },
    checkTeamIn({commit, state, rootState}, payload){
        const tournamentId = state.tournamentItem.id;
        const playerId = rootState.currentUser.user.player.id;
        return axios.post(state.apiUrl+'user/tournament/'+tournamentId+'/participant/checkin',{
            team_id: payload.teamId,
            checkinValue: payload.checkinValue,
            participant_type: 'Team',
            player_id: playerId,
        })
            .then(response => {
                console.log('%ccheck team test ======================', 'color: green')
                console.log(payload)
                console.log(response)
                console.log('%ccheck team test ======================', 'color: green')
                if(response.data.success){
                    commit('checkTeamIn', {
                        participants: response.data.data,
                        checkinValue: payload.checkinValue,
                    });
                }
            })
            .catch(error => {
                console.log(error.response);
            })
    },
    triggerLoading({commit}, isLoading){
        commit('triggerLoading', isLoading);
    },


    setTwitchLive({commit}, payload){
        commit('setTwitchLive', payload.isLive);
    },
    reset({commit}){
        commit('reset');
    },
    getPlayerProfile({commit, state}, playerId){
        return axios.get(state.apiUrl+'player/'+playerId+'/infos')
            .then(response => {
                if(response.data.success){
                    return {
                        success: true,
                        player: response.data.data,
                    }
                }else{
                    return {
                        success: false,
                        errorCode: 404,
                    }
                }
            }).catch(error => {
                return {
                    success: false,
                    errorCode: 404,
                }
            });
    },
    getOrganizerProfile({commit, state}, organizerId){
        return axios.get(state.apiUrl+'organizer/'+organizerId+'/infos')
            .then(response => {
                if(response.data.success){
                    return {
                        success: true,
                        organizer: response.data.data,
                    }
                }else{
                    return {
                        success: false,
                        errorCode: 404,
                    }
                }
            }).catch(error => {
                console.log(error)
                return {
                    success: false,
                    errorCode: 404,
                }
            });
    },
    getTeamProfile({commit, state}, teamId){
        return axios.get(state.apiUrl+'team/'+teamId+'/infos')
            .then(response => {
                if(response.data.success){
                    return {
                        success: true,
                        team: response.data.team
                    }
                }else{
                    return {
                        success: false,
                    }
                }
            })
    },
};
const mutations = {
    setCurrentTournament(state, data){
        state.tournamentItem = data;
        if(state.tournamentItem.bracket){
            state.tournamentItem.bracket.settings = JSON.parse(state.tournamentItem.bracket.settings);
        }
        state.participantType = state.tournamentItem.settings.play_mode.size === 1 ? 'player' : 'team';
        console.log('from mutation');
        console.log(data);
    },
    setPlayerIsParticipant(state, participants){
        console.log('inside set participant mutations ')
        state.playerIsParticipant = true;
        state.tournamentItem.participants_players = participants;
        //rootState.tournament.tournamentsList. for participants
        //rootState.tournament.tournamentsList. for count
    },
     cancelParticipation(state, participants){
        state.playerIsParticipant = false;
        state.playerIsCheckedIn = false;
        state.tournamentItem.participants_players = participants
     },
    checkPlayerIn(state, payload) {
        state.playerIsCheckedIn = payload.checkinValue;
        if(payload.participants){
            state.tournamentItem.participants_players = payload.participants;
        }
    },
    checkParticipation(state, isParticipant){
        state.playerIsParticipant = isParticipant;
        state.participationCheckComplete = true;
    },

    // teams
    setTeamIsParticipant(state, payload){
        state.playerTeamIsParticipant = true;
        state.tournamentItem.participants_teams = payload.participants;
        state.teamParticipatedId = payload.participantTeamId;
    },
    checkTeamParticipation(state, data){
        state.playerTeamIsParticipant = data.isParticipant;
        state.teamParticipatedId = data.team_id;
        state.participationCheckComplete = true;
    },
    cancelTeamParticipation(state, participants){
        state.playerTeamIsParticipant = false;
        state.playerTeamIsCheckedIn = false;
        state.tournamentItem.participants_teams = participants;
    },
    checkTeamIn(state, payload) {
        state.playerTeamIsCheckedIn = payload.checkinValue ;
        if(payload.participants){
            state.tournamentItem.participants_teams = payload.participants;
        }

    },
    setCurrentPlayerTeams(state, teams){
        state.playerTeams = teams;
        state.fetchingTeams = false;
    },
    triggerLoading(state, isLoading){
        state.isLoadingAction = isLoading;
    },

    setTwitchLive(state, isLive){
        state.twitchIsLive = isLive;
    },
    reset(state){
        state.tournamentItem = null;
        state.playerIsParticipant=false;
        state.playerIsCheckedIn=false;
        state.playerTeamIsParticipant=false;
        state.playerTeamIsCheckedIn=false;
        state.playerTeams = [];
        state.teamParticipatedId = null;
        state.isLoadingAction = false;
        state.participantType = null;
        state.twitchIsLive = false;
        state.participationCheckComplete = false;
    }
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
