<template>
    <div class="bracket-container w-100 h-100">
        <!--<EliminationBracket v-if="tournmaentLoaded && bracketStructure"></EliminationBracket> -->
        <BattleRoyalBracket  v-if="tournmaentLoaded && bracketStructure"></BattleRoyalBracket>
        <div class="d-flex flex-column justify-content-center align-items-center w-100 h-100 my-5 mx-auto" v-else>
            <h6 class="no-results-msg text-white font-weight-bold">La liste des participantes confirmées sera disponible avant le début du tournoi.
            </h6>
            <h6 class="no-results-msg-arabic text-white font-weight-bold" dir="rtl">قائمة المشاركات المؤكدات ستكون متاحة قبل بدء البطولة.
            </h6>
        </div>
    </div>
</template>

<script>
    //import EliminationBracket from './EliminationBracket.vue';
    import BattleRoyalBracket from './BattleRoyalBracket.vue';
    export default {
        name: 'BracketViewer',
        components:{
          //  EliminationBracket,
            BattleRoyalBracket
        },
        computed:{
            bracketStructure:{
                get(){
                    if(this.$store.state.currentTournament.tournamentItem){
                        return !!this.$store.state.currentTournament.tournamentItem.bracket.structure;
                    }
                    return false;
                }
            },
            tournmaentLoaded:{
                get(){
                    return !!this.$store.state.currentTournament.tournamentItem;
                }
            }
        }
    
    }
</script>
<style scoped>
    .no-results-msg{
         font-family:'monument_extendedregular', sans-serif;

    }
    .no-results-msg-arabic{
        font-family: 'rtl_saudibold', sans-serif;

    }
</style>